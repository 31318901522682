html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    min-height: 0 !important;
    box-sizing: border-box;
}


#root,
.App {
    height: 100%;
}

:root {
    --text-field-border-radius: 16px;
    --base-spacing: 8px;
    --left-drawer-width: 244px;
    --right-drawer-width: 337px;
    --header-height: calc(40px + var(--base-spacing) * 2*2.5);
    --interaction-event-width: clamp(200px, 800px, 100%);
}

.mdxeditor {
    color: var(--mui-palette-primary-main);
    --basePageBg: var(--mui-palette-background-paper);
}

.mdxeditor-diff-source-wrapper {
    background-color: var(--mui-palette-background-paper);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    border-width: 1px;
    border-color: var(--mui-palette-divider);
    border-style: solid;
}

.mdxeditor-select-content {
    background-color: var(--mui-palette-background-paper);
}

.mdxeditor-toolbar {
    border-radius: 16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    overflow: unset;
    flex-wrap: wrap;
}

.mdxeditor-source-editor {
    padding-bottom: 16px;
}

.grecaptcha-badge {
    visibility: hidden;
}

.session-info-markdown {
    width: 100%;
}

.markdown-body {
    background-color: var(--mui-palette-background-paper);
    color: var(--mui-palette-primary-main);
    font-size: 0.875rem;
}

.markdown-body-default {
    background-color: var(--mui-palette-background-default);
}

.markdown-body .highlight pre,
.markdown-body pre {
    background-color: var(--mui-palette-background-paperSecondary);
    color: var(--mui-palette-primary-main);
}

.markdown-body pre code,
.markdown-body pre tt {
    word-break: break-all;
    word-wrap: break-word;
    white-space: pre-wrap;
}

.markdown-body table th,
.markdown-body table td {
    border: 1px solid var(--mui-palette-divider);
}

.markdown-body table tr {
    background-color: var(--mui-palette-background-paper);
}

.markdown-body table tr:nth-child(2n) {
    background-color: var(--mui-palette-background-paperSecondary);
}

.markdown-body table {
    width: auto;
}


:root,
[data-amplify-theme] {
    --amplify-components-fieldcontrol-border-radius: var(--text-field-border-radius) !important;
    --amplify-components-fieldcontrol-focus-border-color: var(--mui-palette-primary-main) !important;
    --amplify-components-button-active-border-color: var(--mui-palette-primary-main) !important;
    --amplify-internal-button-color: var(--mui-palette-primary-main) !important;
    --amplify-components-passwordfield-button-color: var(--mui-palette-primary-main) !important;
    --amplify-components-authenticator-router-background-color: var(--mui-palette-background-paper) !important;
    --amplify-components-button-primary-background-color: var(--mui-palette-primary-main) !important;
    --amplify-components-button-primary-color: var(--mui-palette-primary-contrastText) !important;
    --amplify-components-button-primary-hover-color: var(--mui-palette-primary-contrastText) !important;
    --amplify-components-button-primary-hover-background-color: var(--mui-palette-primary-dark) !important;
    --amplify-components-button-link-color: var(--mui-palette-primary-main) !important;
    --amplify-components-button-link-hover-color: var(--mui-palette-primary-contrastText) !important;
    --amplify-components-button-link-hover-background-color: var(--mui-palette-primary-dark) !important;
    --amplify-components-button-link-hover-color: var(--mui-palette-primary-contrastText) !important;
    --amplify-colors-font-primary: var(--mui-palette-text-primary) !important;
    --amplify-components-authenticator-router-border-color: var(--mui-palette-primary-main) !important;
    --amplify-components-field-label-color: var(--mui-palette-primary-main) !important;
    --amplify-components-authenticator-router-border-width: 0 !important;
    --amplify-components-authenticator-router-background-color: transparent !important;
}

.auth-page {
    height: 100%;
    display: flex;
}

.auth-error-wrapper {
    flex: 1 1 auto;
    gap: calc(var(--base-spacing) * 2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-inline: calc(var(--base-spacing) * 4);
}

.auth-action-panel {
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
}

.auth-action-panel-header {

    margin: calc(var(--base-spacing) * 2.5);
    margin-top: calc(var(--base-spacing) * 1.3);
    text-align: left;
    flex: 0 0 auto;
}

.auth-action-panel-content {
    margin: auto;
    max-width: 30rem;
}

.auth-form-wrapper {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.auth-action-panel-footer {
    flex: 0 0 auto;
    margin: 1em;
    text-align: center;
    max-width: 30em;
    align-self: center;
    padding-inline: 2em;
}

.auth-image-panel {
    flex: 1 1 50%;
    background-size: cover;
    background-position: center;
}

.auth-image-panel-text {
    font-family: 'Silkscreen', cursive;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 2.7rem;
    font-weight: 600;
    text-align: center;
    line-height: normal;
    letter-spacing: -5.616px;
}

.auth-image-panel-text-divider {
    margin: 1em;
}

.auth-image-panel-text p {
    margin: 0.25em;
    text-transform: uppercase;
    text-shadow: black 1px 1px;
}

.verify-form {
    display: flex;
    flex-direction: column;
    gap: calc(var(--base-spacing) * 3);
    align-items: center;
}

.verification-input-container {
    margin: calc(var(--base-spacing) * 3);
}

.verification-input-character {
    font-size: 24px;
    border-radius: var(--text-field-border-radius);
    background-color: var(--mui-palette-primary-main);
}

.verification-input-character-selected {
    color: var(--mui-palette-primary-contrastText);
    outline: 2px solid var(--mui-palette-primary-main);
}

.internal-page {
    padding-top: var(--header-height);
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
}

.internal-page-left-drawer {
    width: var(--left-drawer-width);
    display: flex;
    flex-direction: column;
    flex: 1;
    /* padding-inline: calc(var(--base-spacing) * 2); */
}

.internal-page-left-drawer-actions {
    display: flex;
    flex-direction: column;
    gap: calc(var(--base-spacing));
    flex: 0 0 auto;
    padding-top: calc(var(--base-spacing) * 2);
    margin-inline: calc(var(--base-spacing) * 2);
}

.internal-page-left-drawer-sessions {
    flex: 0 0 auto;
    overflow-y: auto;
    padding: 0 !important;
}

.internal-page-left-drawer ul:last-of-type {
    flex: 1 1 auto !important;
}

.internal-page-left-drawer-footer {
    flex: 0 0 auto;
    padding-block: calc(var(--base-spacing) * 2);

    margin-inline: calc(var(--base-spacing) * 2);
}

.internal-page-header {
    z-index: 1100;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: calc(var(--base-spacing) * 3);
    padding: calc(var(--base-spacing) * 2.5);
    border-bottom: 1px solid var(--mui-palette-divider);
    height: var(--header-height)
}

.internal-page-header-buttons {
    display: flex;
    gap: calc(var(--base-spacing) * 1);
    align-items: center;
}

.internal-page-header-home {
    display: flex;
    flex: 0 0 calc(var(--left-drawer-width) - calc(var(--base-spacing) * 2.5));
}

.internal-page-header-logo {
    cursor: pointer;
}

.internal-page-header-extra-controls {
    display: flex;
    gap: calc(var(--base-spacing) * 3);
}

.home-page-body {
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: calc(var(--base-spacing) * 6);
    padding-top: calc(var(--base-spacing) * 2);
}

.hub-page-padded-section {
    padding-inline: calc(var(--base-spacing) * 3);
}

.hub-page-section-title,
.hub-page-carousel,
.home-page-section-title,
.home-page-carousel {
    padding-left: calc(var(--base-spacing) * 3);
}

.horizontal-carrousel-items {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    gap: calc(var(--base-spacing) * 2);
    overflow: auto;
}

.hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.horizontal-carrousel-items>* {
    flex: 0 0 auto;
}

.home-all-sessions {
    display: flex;
    gap: calc(var(--base-spacing) * 2);
    padding-inline: calc(var(--base-spacing) * 2);
}

.home-all-sessions>* {
    flex: 1 1 50%;
}

.home-session-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: calc(var(--base-spacing) * 2);
}

.home-session-items>div {
    flex: 1 1 250px;
}

.interaction-card-large {
    width: 200px;
    height: 200px;
}

.card-stats-small {
    display: flex;
    gap: var(--base-spacing);
}

.session-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: calc(var(--base-spacing) * 2);
}

.card-stat-small {
    display: flex;
    gap: calc(var(--base-spacing) / 2);
    padding-top: calc(var(--base-spacing) / 2);
}

.internal-page-content-body-header {
    width: 100%;
    flex: 0 0 auto;
    display: flex;
    gap: calc(var(--base-spacing) * 2);
    justify-content: space-between;
    padding-inline: calc(var(--base-spacing) * 3);
    padding-block: calc(var(--base-spacing) * 1.5);
}

.internal-page-content-body {
    padding-inline: calc(var(--base-spacing) * 3);
}

.session-page-title {
    padding-top: calc(var(--base-spacing) * 2);
    padding-left: calc(var(--base-spacing) * 4);
    padding-bottom: calc(var(--base-spacing) * 4);
    display: none;
}

.banner {
    border-radius: calc(var(--base-spacing) * 2);
    display: flex;
    flex-direction: column;
    margin-inline: calc(var(--base-spacing) * 2);
    margin-top: calc(var(--base-spacing) * 2);
}

.banner-large {
    padding: calc(var(--base-spacing) * 4);
    /* padding-top: calc(var(--base-spacing) * 8); */
    margin-bottom: 0;
    gap: calc(var(--base-spacing) * 2);
}

.interaction-page-banner {
    margin-bottom: calc(var(--base-spacing));
    width: clamp(200px, 1000px, 100%);
    margin-inline: auto;
}

.session-page-banner-small {
    padding: calc(var(--base-spacing) * 4);
    border-radius: calc(var(--base-spacing) * 2);
    margin-inline: calc(var(--base-spacing) * 2);
}

.session-page-banner-large-info {
    display: flex;
    gap: calc(var(--base-spacing) * 3);
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.session-page-banner-large-info-inner {
    display: flex;
    gap: calc(var(--base-spacing) * 3);
    flex-direction: row;
}

.session-page-banner-large-info-members {
    display: flex;
    gap: calc(var(--base-spacing) * 1);
    align-items: center;
}

.session-page-content-nav {
    padding: calc(var(--base-spacing) * 2);
    margin-block: calc(var(--base-spacing) * 2);
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: var(--mui-palette-background-default);
}

.session-page-sources-section {
    padding-inline: calc(var(--base-spacing) * 2);
    padding-bottom: calc(var(--base-spacing) * 2);
}

.internal-page-content-body-header-actions {
    display: flex;
    gap: calc(var(--base-spacing));
    flex: 0 0 auto;
    align-items: center;
}

.internal-page-drawer {
    flex: 0 0 auto;
    width: var(--right-drawer-width);
    height: 100%;
    display: flex;
    flex-direction: column;
}

.internal-page-drawer-header {
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: calc(var(--base-spacing) * 2);
    padding: calc(var(--base-spacing) * 2);
}

.internal-page-drawer-body {
    width: 100%;
    padding: calc(var(--base-spacing) * 2);
    overflow-y: auto;
}

.sources-page-drawer {
    flex: 0 0 457px;
}

.sources-page-drawer-body {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: calc(var(--base-spacing) * 4);
    overflow: auto;
}

.sources-page-drawer-body-header {
    flex: 0 0 10%;
    padding-inline: calc(var(--base-spacing) * 4);
    display: flex;
    flex-direction: column;
    gap: calc(var(--base-spacing) * 3);
}

.sources-page-mobile-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.internal-page-drawer-body-subtitle {
    display: flex;
    gap: calc(var(--base-spacing) * 2);
    justify-content: space-between;
    width: 100%;
}

.sources-page-drawer-body-metadata {
    padding-inline: calc(var(--base-spacing) * 4);
    display: flex;
    flex-wrap: wrap;
    row-gap: calc(var(--base-spacing) * 4);
    flex: 1 1 40%;
}

.sources-page-drawer-body-metadata>* {
    flex: 1 1 45%;
}

.sources-page-drawer-footer {

    padding-inline: calc(var(--base-spacing) * 4);
    padding-block: calc(var(--base-spacing) * 2);

}

.internal-page-footer {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    gap: calc(var(--base-spacing));
    padding: calc(var(--base-spacing) * 2);
}

.internal-page-footer-no-content {
    justify-content: center;
}

.session-interactions-grid {
    flex: 1 1 auto;
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    gap: calc(var(--base-spacing) * 2);
    padding-inline: calc(var(--base-spacing) * 2);
    padding-bottom: calc(var(--base-spacing) * 2);
}

.internal-page-content {
    flex: 1 1 85%;
    display: flex;
    overflow: auto;
    width: 100%;
    height: 100%;
}

.internal-page-center {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;
}

.internal-page-center>* {
    flex: 0 1 auto;
}

.session-placeholder {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-inline: calc(var(--base-spacing) * 2);
}

.session-description {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-inline: calc(var(--base-spacing) * 2);
    padding-bottom: calc(var(--base-spacing) * 2);
    position: relative;
    gap: calc(var(--base-spacing) * 2);
}

.interaction-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: calc(var(--base-spacing) * 1.5);
}

.interaction-card-header {
    flex: 0 0 auto;
    display: flex;
    gap: calc(var(--base-spacing) * 1.5);
    justify-content: space-between;
    align-items: center;
}

.interaction-card-body {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding-bottom: calc(var(--base-spacing) * 1.5);
    gap: calc(var(--base-spacing) * 1.5);
}

.interaction-container-events {
    overflow: auto;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    gap: calc(var(--base-spacing) * 2);
    align-items: center;
}

.interaction-container-events>li {
    justify-content: center;
}

.sources-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: calc(var(--base-spacing) * 1.5);

}

.recent-interaction-carousel-item {
    width: 300px;
    height: 200px;
}

.mobile-divider {
    display: none;
}

.modal {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 600px;
    margin: auto;
    max-height: 100%;
}

.modal-small {
    width: 480px;
}

.modal-content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding: calc(var(--base-spacing) * 4);
    gap: calc(var(--base-spacing) * 3);
    overflow-y: auto;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
    padding: calc(var(--base-spacing) * 2);
    gap: calc(var(--base-spacing) * 2);
}

.modal-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: calc(var(--base-spacing) * 3);
}

.modal-form-field {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: calc(var(--base-spacing) * 1.5);
}

.modal-mobile-header {
    height: calc(var(--base-spacing) * 5);
    padding-top: calc(var(--base-spacing) * 2);
    padding-left: calc(var(--base-spacing) * 2);
}

.confirm-modal-contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: calc(var(--base-spacing) * 3);
}

.confirm-modal-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(var(--base-spacing) * 8);
    width: calc(var(--base-spacing) * 8);
    border-radius: 50%;
    background-color: var(--mui-palette-background-paper);
}

.confirm-modal-actions {
    text-align: center;
    padding-top: calc(var(--base-spacing) * 2);
}

.modal-close-icon {
    position: absolute;
    top: calc(var(--base-spacing) * 1.5);
    right: calc(var(--base-spacing) * 1.5);
}

.sources-page-content {
    height: 100%;
    flex: 1 1 auto;
    padding-inline: calc(var(--base-spacing) * 2);
    display: flex;
    gap: calc(var(--base-spacing) * 2);
    flex-direction: column;
    overflow-y: hidden;
}

.sources-page-content-scrollable-child {
    height: 100%;
    overflow-y: auto;
}

.sources-page-table-row {
    cursor: pointer;
}

.sources-page-table-cell-inline {
    display: flex;
    flex-direction: row;
    gap: calc(var(--base-spacing) * 1);
    align-items: center;
}

.sources-grid {
    display: grid;
    grid-auto-rows: 296px;
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
    gap: calc(var(--base-spacing) * 2);
    padding-bottom: calc(var(--base-spacing) * 2);
}

.spacing-tiny {
    padding-top: calc(var(--base-spacing) * 0.5);
}

.spacing-small {
    padding-top: calc(var(--base-spacing) * 1.5);
}

.spacing-medium {
    padding-top: calc(var(--base-spacing) * 2);
}

.spacing-large {
    padding-top: calc(var(--base-spacing) * 3);
}

.source-card-large-content {
    flex: 1 1 60%;
    padding: calc(var(--base-spacing) * 2);
    width: 100%;
    display: flex;
    flex-direction: column;
}

.source-card-large-content-body {
    flex: 1 1 auto;
}

.source-card-large-content-footer {
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
}

.source-card-large-content-footer-icons {
    display: flex;
    gap: calc(var(--base-spacing) * 1.25);
    flex: 0 0 auto;
}

.source-card-large-content-footer-item {
    display: flex;
    gap: calc(var(--base-spacing) / 2);
}

.source-card-display-source {
    display: flex;
    gap: calc(var(--base-spacing) * 1);
    align-items: center;
}

.content-reference {
    cursor: pointer;
}

.hub-page-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: calc(var(--base-spacing) * 4);
    gap: calc(var(--base-spacing) * 4);
    height: 100%;
    overflow-y: auto;
}

.hub-page-header {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.hub-page-header-subtitle {
    text-align: center;
    max-width: 550px;
    margin: auto;
}

.hub-topics-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
    gap: calc(var(--base-spacing) * 1);
}

.hub-page-explore-more-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
    gap: calc(var(--base-spacing) * 1);
}

.mobile-only,
.mobile-only-flex {
    display: none !important;
}

.share-session-toggle-group {
    justify-content: center;
    padding: 1px;
    border: 1px solid var(--mui-palette-divider);
}

.share-session-toggle-group-button {
    flex: 1 1 100%;
    gap: calc(var(--base-spacing) * 0.5);
    border-radius: var(--text-field-border-radius) !important;
    border: none !important;
}

.share-session-member-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-inline: 0;
    gap: calc(var(--base-spacing) * 0.5);
}

.share-session-member-item-header {
    display: flex;
    gap: calc(var(--base-spacing) * 2);
}

.share-session-member-item-header-names {
    display: flex;
    flex-direction: column;
}

.share-session-member-item-role {
    display: flex;
    gap: calc(var(--base-spacing) * 1);
}

.tiny-icon {
    width: calc(var(--base-spacing) *2) !important;
    height: calc(var(--base-spacing) *2) !important;
}

.session-card-large-contents {
    display: flex;
    flex-direction: column;
    align-items: 'flex-start';
    height: 100%;
}

.interaction-page-header {
    position: relative
}

.interaction-page-sources-overlay {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1100;
    flex-direction: column;
}

.interaction-page-sources-overlay>* {
    flex: 1 1 auto;
}

.qa-events-container-card {
    display: flex;
    flex-direction: column;
    width: var(--interaction-event-width);
    margin: auto;
}

.legal-text {
    margin-inline: auto;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    gap: calc(var(--base-spacing) * 3);
    padding: calc(var(--base-spacing) * 4);
    padding-top: calc(var(--base-spacing) * 8);
}

.auth-action-panel-content-width {
    width: 480px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.continue-as-guest-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: calc(var(--base-spacing) * 2);
    padding-top: calc(var(--base-spacing) * 4);
    padding-inline: calc(var(--base-spacing) * 4);
}

.auth-action-panel-content-width button {
    width: 70%;
}

.auth-action-panel-content-width a {
    max-width: 80%;
}

.tool-component {
    background-color: var(--mui-palette-background-paperSecondary);
    border-radius: 6px;
}

.tool-component-contents {
    padding-bottom: 8px;
    padding-inline: 16px;
}

.session-page-upload-box {
    padding-inline: calc(var(--base-spacing) * 2);
    padding-block: calc(var(--base-spacing) * 10);
    flex: 1 0 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: calc(var(--base-spacing) * 1);
}

.max-lines-1 {
    max-height: 1lh;
    overflow: hidden;
}

.max-lines-2 {
    max-height: 2lh;
    overflow: hidden;
}
.max-lines-3 {
    max-height: 3lh;
    overflow: hidden;
}

@media only screen and (max-device-width: 768px) {
    :root {
        --header-height: calc(39px + var(--base-spacing) * 2*1.5);
    }

    .auth-action-panel-content-width {
        width: 328px;
    }

    .internal-page-content-body-header {
        padding: calc(var(--base-spacing) * 2);
    }

    .internal-page-content-body {
        padding-inline: calc(var(--base-spacing) * 2);
    }

    #sentry-feedback {
        display: none;
    }

    .auth-image-panel {
        display: none;
    }

    .auth-action-panel-header {
        text-align: center;
    }

    .desktop-only {
        display: none !important;
    }


    .mobile-only {
        display: block !important;
    }

    .mobile-only-flex {
        display: flex !important;
    }

    .banner-large {
        padding: calc(var(--base-spacing) * 2);
        gap: calc(var(--base-spacing) * 2);
    }

    .hub-topics-grid,
    .desktop-divider,
    .internal-page-drawer,
    .internal-page-left-drawer {
        display: none;
    }

    .session-interactions-grid {
        display: grid;
        grid-template-columns: 1fr;
        padding-inline: calc(var(--base-spacing) * 2);
    }

    .mobile-divider,
    .session-page-title {
        display: block;
        padding-inline: calc(var(--base-spacing) * 2);
    }

    .modal {
        width: 100%;
        height: calc(100% - calc(var(--base-spacing) * 4));
        border-radius: calc(100% - calc(var(--base-spacing) * 4)) calc(100% - calc(var(--base-spacing) * 4)) 0 0;
        margin-top: calc(var(--base-spacing) * 4);
    }

    .internal-page-header {
        gap: calc(var(--base-spacing) * 1);
        padding: calc(var(--base-spacing) * 1.5);
    }

    .internal-page-header-home {
        flex: 0 0 auto;
    }

    .hub-page-padded-section {
        padding-inline: calc(var(--base-spacing) * 2);
    }

    .hub-page-section-title,
    .hub-page-carousel,
    .home-page-section-title,
    .home-page-carousel {
        padding-left: calc(var(--base-spacing) * 2);
    }

    .legal-text {
        padding-top: calc(var(--base-spacing) * 4);
    }
}

@media only screen and (max-device-width: 550px) {
    .home-all-sessions {
        flex-direction: column;
        padding-inline: calc(var(--base-spacing) * 1);
    }

    .share-session-toggle-group {
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (max-device-width: 1119px) {
    :root {
        --right-drawer-width: 240px;
        --left-drawer-width: 200px;
    }
}

@media print {
    .internal-page-center {
        overflow: unset;
    }
    
    .tool-component-collapse {
        min-width: unset !important;
        height: unset !important;
        visibility: visible !important;
    }

    body {
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
    }

    .internal-page-footer {
        display: none;
    }

    .internal-page-header {
        display: none;
    }

    .internal-page-left-drawer {
        display: none;
    }

    .internal-page-drawer {
        display: none;
    }

    .interaction-container-events {
        overflow: visible;
        height: 100%;
    }

    .internal-page {
        padding: 0;
    }

    .internal-page-content {
        overflow: visible;
    }

    #sentry-feedback {
        display: none;
    }
}